/**
 *
 */
export default defineNuxtRouteMiddleware(() => {
    const hotelStore = useHotelStore();
    const shopStore = useShopStore();
    const flowStore = useFlowStore();

    if (!shopStore.currentFlowProduct
        || !configProductsKeyed[shopStore.currentFlowProduct.config.key].upsells.includes('hotel')
    ) {
        hotelStore.reset();
        return navigateTo(getFirstFlowRoute(flowStore.productConfig?.steps));
    }
});
